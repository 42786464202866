<template>
  <div class="filter-box py-5 px-4 mb-6">
    <h3 class="primary--text mb-3">
      <v-icon class="ml-1">mdi-filter</v-icon>البحث حسب
    </h3>
    <v-row align="center">
      <!-- QUERY -->

      <!-- Q -->
      <v-col class="d-flex" cols="12" sm="6">
        <v-text-field
          rounded
          outlined
          solo
          hide-details
          clearable
          label="ابحث بالاسم او الهاتف "
          v-model="filters.search"
        ></v-text-field>
      </v-col>

      <!-- SEARCH BUTTON -->
      <v-col class="d-flex" cols="6">
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          @click="handleFilters('search')"
          large
          color="primary"
          >بحث</v-btn
        >

        <v-btn class="mr-2" @click="handleGetAll()" large color="primary"
          >عرض الكل
        </v-btn>
        <v-btn
          class="mr-2"
          @click="$router.replace({ query: {flag:0 ,active:1} }); handleActive(); "
          large
          color="primary"
        >
          عرض النشط
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "filters-box",

  data: function () {
    return {
      categoriesLoading: false,

      filters: {
        search: '',
        active: null,
      },
    };
  },

  created() {},

  computed: {},

  methods: {
    ...mapActions("users", ["fetchRecords"]),
    ...mapMutations("users", ["SET_FILTERS"]),
    handleActive(){
      this.filters= {
        search: '',
        active: 1,
      },
      this.$emit('active', this.filters.active);
    },
    handleFilters(){
       this.SET_FILTERS({...this.$route.query , search : this.filters.search , page : 1 , active:this.filters.active}); 
       this.$router.replace({query:{...this.$route.query ,search : this.filters.search , page:1 } });
       if(this.$route.query.page == 1){
                this.fetchRecords();
        }
     },
     handleGetAll(){
      this.filters={active:null , search : '' , sub_category_id :''}
      this.$router.replace({query:{flag:1,search : '', active:0}})
      // this.fetchRecords('')
     },
  },
};
</script>
