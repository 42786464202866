<template>
  <div class="page">
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-account</v-icon>
      ادارة العملاء
      <back-button />
    </h2>
    <hr class="my-5 header-divider" />
    <!-- filter -->
    <!-- <filters-box /> -->
    <filters-box @active="handleGetAllActive"/>
    <v-chip class="mb-2" label  color="secondary">  <v-icon left>
        mdi-account-circle-outline
      </v-icon> عدد المستخدمين  {{totalUsers}}</v-chip>
    <!-- TABLE -->
    <v-data-table
      item-key="id"
      class="elevation-1"
      :loading="table.loading"
      :headers="table.headers"
      :items="records"
      loading-text="Downloading Data"
      no-data-text="No Data"
      disable-filtering
      disable-pagination
      disable-sort
      hide-default-footer
    >


      <template v-slot:item.created_at="{ item }">
        {{ new Date(item.created_at).toLocaleString("ar") }}
      </template>


      <template v-slot:item.blocked="{ item }">
        <v-switch
          v-if="item.active == 0"
          inset
          :input-value="false"
          @change="unblock(item)"
        ></v-switch>
        <v-switch
          v-else="item.active == 1"
          color="success"
          inset
          :input-value="true"
          @change="block(item)"
        ></v-switch>
      </template>
    </v-data-table>

    <!-- PAGINATION -->
    <!-- "totalPages" -->
    <div class="text-center mt-7">
      <v-pagination
        total-visible="7"
        circle
        :value="+getFilter.page"
        :length="Math.ceil(total / 10)"
        @input="changePage"
      ></v-pagination>
    </div>
  </div>
</template>


<script>
import RecordForm from "./RecordForm";
import { mapState, mapMutations, mapGetters } from "vuex";
import FiltersBox from "./FilterBox.vue";

export default {
  name: "users",
  components: { RecordForm, FiltersBox },

  data: function() {
    return {
      activeStatus:0,
      table: {
        headers: [
          {
            value: "name",
            text: "الاسم"
          },
          {
            value: "phone",
            text: "الهاتف"
          },
          // {
          //   value: "email",
          //   text: "الايميل"
          // },

          {
            value: "created_at",
            text: " تاريخ التسجيل"
          },

          {
            value: "blocked",
            text: "تفعيل/تعطيل"
          }
        ],
        loading: false
      }
    };
  },

  computed: {
    ...mapState("users", ["records", "filters", "total","totalUsers"]),
    ...mapGetters("users" , ["getFilter"])
  },

  watch: {
    $route: {
      async handler(route) {
        // console.log(route.query)
        if (!route.query.page) {
          await this.$router.replace({ query: { ...route.query, page: 1 } });
          return;
        }
        this.SET_FILTERS({...this.filters,...route.query});
           this.fetchRecords();
      
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    handleGetAllActive(val){
        this.activeStatus = val
        return val;
    },
    ...mapMutations("users", [
      "SHOW_RECORD_FORM",
      "SET_EDIT_MODE",
      "SET_RECORD_FORM",
      "SET_FILTERS"
    ]),
    changePage(val) {
      // console.log(val)
      // console.log(this.activeStatus)
      this.$router
        .replace({
          query: { ...this.$route.query, page: val},
        })
        .catch(() => {});
    },
    fetchRecords(active) {
      this.table.loading = true;
      this.$store
        .dispatch("users/fetchRecords",active)
        .catch(error => {
          this.$bus.$emit("showSnackbar", {
            text: "An error occurred when rendering the data.",
            color: "red darken-3"
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    async unblock(selectedRecord) {
      this.table.loading = true;

      this.$store
        .dispatch("users/unblock", { record: selectedRecord })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: `UNBLOCK  ${selectedRecord.name}`,
            color: "success"
          });
          this.fetchRecords();
        })
        .catch(error => {
          this.$bus.$emit("showSnackbar", {
            text: "An error occurred when unblocking the user.",
            color: "red darken-3"
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    async block(selectedRecord) {
      this.table.loading = true;

      this.$store
        .dispatch("users/block", { record: selectedRecord })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: `BLOCK ${selectedRecord.name}`,
            color: "warning"
          });
          this.fetchRecords();
        })
        .catch(error => {
          this.$bus.$emit("showSnackbar", {
            text: "An error occurred when blocking the data.",
            color: "red darken-3"
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    }
  }
};
</script>
