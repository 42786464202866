<template>
  <v-dialog :value="showRecordForm" @input="v => v || HIDE_RECORD_FORM()" max-width="600px" scrollable>
    <v-card>
      <!-- TITLE -->
      <v-card-title class="primary">
        <span class="headline">{{ editMode ? 'Edit User' : 'Add User'}}</span>
      </v-card-title>

      <!-- INPUTS -->
      <v-card-text class="pt-5 pb-0">
        <v-container>
          <v-progress-linear
            v-if="formLoading"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          <div v-else>
            <v-text-field
              dense
              outlined
              label="Name"
              v-model="form.name"
              :error-messages="errors.name"
            ></v-text-field>

            <v-file-input
              dense
              outlined
              label="User Icon"
              prepend-icon="mdi-image"
              accept="image/*"
              v-model="form.image"
              :error-messages="errors.image"
            ></v-file-input>
          </div>
        </v-container>
      </v-card-text>

      <!-- ACTIONS -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          dark
          color="primary"
          elevation="1"
          :loading="formLoading"
          @click="editMode ? update(form) : create(form)"
        >حفظ</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'record-form',


  data: function () {
    return {
      form: {},
      errors: {},
      formLoading: false,
    }
  },


  computed: {
    ...mapState(
      'users',
      {
        editMode: state => state.editMode,
        showRecordForm: state => state.showRecordForm,
        recordForm: state => {
          return {
            id: state.recordForm.id,
            name: state.recordForm.name,
          };
        }
      }
    )
  },


  watch: {
    recordForm(form) { this.form = form; }
  },


  methods: {
    ...mapMutations(
      'users',
      [
        'HIDE_RECORD_FORM'
      ]
    ),
    ...mapActions("users",["fetchRecords"]),

    create(form) {
      this.formLoading = true;

      this.$store.dispatch('users/create', { form })
        .then((response) => {
          this.$bus.$emit('showSnackbar', {
            text: 'The data was successfully inserted.',
            color: 'success'
          });

          this.$emit('update');
          this.HIDE_RECORD_FORM();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          this.$bus.$emit('showSnackbar', {
            text: 'When adding user, an error occurred.data',
            color: 'red darken-3'
          });
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords()
        });
    },

    update(form) {
      this.formLoading = true;

      this.$store.dispatch('users/update', { form })
        .then(() => {
          this.$bus.$emit('showSnackbar', {
            text: 'The data was successfully updated.',
            color: 'success'
          });

          this.$emit('update');
          this.HIDE_RECORD_FORM();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          this.$bus.$emit('showSnackbar', {
            text: 'When updating data, an error occurred.data',
            color: 'red darken-3'
          });
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords()
        });
    }
  }
}
</script>
